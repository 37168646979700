import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import { SocialIcons } from '@/components/atomic/molecules';
import { useWindowSize } from '@/hooks';

import { LanguageSwitcher } from '../../atoms';

interface CarouselImage {
  alt: string;
  href: string;
  src: string;
}

export const LandingHeader: React.FC = () => {
  const { t } = useTranslation();

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const sliderRef = useRef();
  const { width } = useWindowSize();
  const [backgroundImages, setBackgroundImages] = useState<CarouselImage[]>([]);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    adaptiveHeight: true,
    swipeToSlide: true,
    beforeChange: (next: any) => setCurrentSlideIndex(next),
    afterChange: (current: any) => setCurrentSlideIndex(current),
  };

  useEffect(() => {
    if (width <= 428) {
      setBackgroundImages([
        {
          alt: 'Get a pylon and showcase your creativity with your NFT collections.',
          src: '/assets/studio/studio1mb.png',
          href: 'https://metapylon.io/mint',
        },
        {
          alt: 'BUY SHIT NFT STICKER PAC made in collaboration with SHIT skateboard company.',
          src: '/assets/studio/studio2mb.png',
          href: 'https://metapylon.io/studio/stickerpacks?id=28e82b85-6106-4c1e-9f16-579e56e94e1c',
        },
        {
          alt: 'Buy ANALOGOZI STICKER PAC made with a fusion of street style and Japanese animation style techniques.',
          src: '/assets/studio/studio3mb.png',
          href: 'https://metapylon.io/studio/stickerpacks?id=025b5736-33cf-4082-a3b9-44c50a73a451',
        },
      ]);
    } else {
      setBackgroundImages([
        {
          alt: 'Get a pylon and showcase your creativity with your NFT collections.',
          src: '/assets/studio/studio1.png',
          href: 'https://metapylon.io/mint',
        },
        {
          alt: 'BUY SHIT NFT STICKER PAC made in collaboration with SHIT skateboard company.',
          src: '/assets/studio/studio2.png',
          href: 'https://metapylon.io/studio/stickerpacks?id=28e82b85-6106-4c1e-9f16-579e56e94e1c',
        },
        {
          alt: 'Buy ANALOGOZI STICKER PAC made with a fusion of street style and Japanese animation style techniques.',
          src: '/assets/studio/studio3.png',
          href: 'https://metapylon.io/studio/stickerpacks?id=025b5736-33cf-4082-a3b9-44c50a73a451',
        },
      ]);
    }
  }, [width]);

  return (
    <header>
      <div className="mt-8 flex items-center justify-end space-x-5">
        <LanguageSwitcher />
        <SocialIcons
          boatLink="https://opensea.io/collection/metapylonnft"
          discordLink="https://discord.com/invite/mTMEwUNYEe"
          twitterLink="https://twitter.com/MetaPylon"
          className="mt-0"
        />
      </div>

      <div className="mt-16 flex flex-col items-center justify-center text-center">
        <Image src="/assets/landing/logo.png" alt="" width="194" height="248" />
        <h3 className="mt-12 text-3xl leading-[3rem] lg:text-5xl lg:leading-[3.875rem]">
          <span className="font-goboldhollow">
            {t('Create your distinguished')} <br />
            {t('META PYLON with')}
          </span>
          &nbsp;
          <span className="font-goboldRegular">{t('Studio')}</span>
        </h3>
        <p className="mt-3 max-w-md text-base font-light leading-8 text-black-500">
          {t(
            'META PYLON Studio is an exclusive platform for META PYLON Holder to customize META PYLON NFT.'
          )}
        </p>
        <div className="mt-12 h-[451px] w-full lg:mt-24">
          <Slider
            {...settings}
            ref={(slider) => {
              // @ts-ignore
              sliderRef!.current = slider;
            }}
          >
            {backgroundImages.map((image: CarouselImage, index) => {
              return (
                <Link key={index} href={image?.href}>
                  <a>
                    <div className="relative h-[451px] w-full">
                      <Image
                        src={image?.src}
                        alt={image?.alt}
                        layout="fill"
                        objectFit="contain"
                      />
                    </div>
                  </a>
                </Link>
              );
            })}
          </Slider>
        </div>
        <div className="flex items-center space-x-2">
          {new Array(backgroundImages.length).fill(null).map((_, index) => {
            return (
              <img
                key={index}
                className="z-10 h-[14px] w-[12px] cursor-pointer"
                onClick={() => {
                  setCurrentSlideIndex(index);
                  // @ts-ignore
                  sliderRef.current!.slickGoTo(index);
                }}
                src={`${
                  currentSlideIndex === index
                    ? '/assets/landing/background/cone-outline.svg'
                    : '/assets/landing/background/cone-black.svg'
                }`}
                alt="cone"
              />
            );
          })}
        </div>
      </div>
    </header>
  );
};
