import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import { useStickerPack, useStickerPacks } from '@/hooks/studio/useStickerPack';

import { StickerPacksHeading } from '../../atoms';
import { StickerCardShimmer } from '../../atoms/StickerCardShimmer';
import { StickersPackModal } from '../../molecules';
import { StickersTabBar } from '../StickersTabBar';

interface StickerPackProps {
  amount: string;
  cover_image: string;
  created_at: string;
  description: string;
  id: string;
  image: string;
  is_public: boolean;
  label: string;
  mouseover_image: string;
  name: string;
  order: number;
  sample_image: string;
  status: string;
  updated_at: string;
  limit: number;
  sale_packs: number;
  sticker_per_pack: number;
  total_packs: number;
  total_stickers: number;
  purchaseCount: number;
}

export const StudioStickerDetailSection: React.FC = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useStickerPacks();

  const router = useRouter();
  const { id } = router.query;

  const { data: selectedPack, isLoading: stickerLoading } = useStickerPack(
    id?.toString()
  );

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    setModalVisible(!!id);
  }, [id]);

  const handleCloseModal = () => {
    setModalVisible(false);
    router.push('/studio/stickerpacks', undefined, { scroll: false });
  };

  return (
    <>
      {modalVisible && (
        <StickersPackModal
          visible={modalVisible}
          data={selectedPack}
          loading={stickerLoading}
          setVisible={handleCloseModal}
          type="buy"
        />
      )}
      <div className="px-5 pt-12 pb-6 font-goboldhollow text-4xl uppercase md:px-0">
        {t('Sticker Packs')}
      </div>

      <StickersTabBar currentTab="packs" />
      <StickerPacksHeading
        title={t('Original Sticker Pack')}
        subTitle={t(
          'Get META STICKER to make your META PYLON look super cooler!!'
        )}
      />

      {isLoading ? (
        <div className=" container mx-auto grid grid-cols-1 gap-2 pt-5 md:grid-cols-2 md:px-0 lg:grid lg:grid-cols-3 lg:gap-20 lg:space-y-0 xl:grid-cols-4  2xl:gap-40">
          {[...Array(4)].map((_, i) => (
            <StickerCardShimmer key={i} />
          ))}
        </div>
      ) : null}

      <div className="container mx-auto mb-10 grid grid-cols-1 gap-x-10 gap-y-5 pt-5 md:grid-cols-2 md:px-0 lg:grid lg:grid-cols-3 xl:grid-cols-4 ">
        {data &&
          data?.map((sticker: StickerPackProps, index: number) => (
            <div
              key={index}
              className="flex cursor-pointer flex-col text-center"
              onClick={() => {
                router.push(
                  {
                    pathname: '/studio/stickerpacks',
                    query: { id: sticker.id },
                  },
                  undefined,
                  { scroll: false }
                );
              }}
            >
              <div
                className="group mx-auto flex max-w-[190px] flex-col"
                onMouseLeave={(e) =>
                  e.currentTarget.children[0]?.children[0]?.setAttribute(
                    'src',
                    sticker.image
                  )
                }
                onMouseOver={(e) =>
                  e.currentTarget.children[0]?.children[0]?.setAttribute(
                    'src',
                    sticker.mouseover_image
                  )
                }
              >
                <div className="flex h-[289px] w-full justify-center rounded">
                  <img
                    src={sticker.image}
                    alt="META STICKER"
                    className={`relative min-w-[190px] duration-300 group-hover:rotate-6 h-[277px] group-hover:h-[289px] ${
                      sticker.status === 'TBA' ? 'grayscale opacity-80' : ''
                    }`}
                  />
                </div>
                <div className="px-5">
                  <div
                    className={`mt-2 mb-1 uppercase font-hiraginoBold ${
                      sticker.status === 'TBA' ? 'text-gray-500' : null
                    }`}
                  >
                    {sticker.name}
                  </div>
                  {sticker.status === 'NOW ON SALE' ||
                  sticker.status === 'LIMITED' ? (
                    <div className={`my-1 uppercase font-hiraginoBold`}>
                      {sticker.amount} ETH
                    </div>
                  ) : null}

                  <div className="relative my-2 mb-0 font-goboldRegular uppercase">
                    <div className="inline-block text-center">
                      {sticker.status} {sticker.purchaseCount}/
                      {sticker.sale_packs || 200}
                      <div className="ml-[-5%] mt-[-15px] h-[5px] w-[110%] bg-yellow-500" />
                    </div>
                  </div>
                  {sticker.purchaseCount >= sticker.sale_packs && (
                    <div className="inline-block text-center">{t('SOLD')}</div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};
