import { useTranslation } from 'next-i18next';
import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useMutation, useQueryClient } from 'react-query';
import { twMerge } from 'tailwind-merge';

import { useLoggedIn } from '@/context/useLoggedIn';
import { useMetaMask } from '@/hooks';
import { web3Util } from '@/utils';
import { API } from '@/utils/api';

export const Buying = () => {
  const { t } = useTranslation();

  const { connectMetamask } = useMetaMask();
  const queryClient = useQueryClient();

  const { loggedIn } = useLoggedIn();
  // const { data: queueData } = useQueueStatus(loggedIn);

  const buyPylons = useMutation(
    async () => {
      await web3Util.changeNetwork();
      const { data } = await API.post<any>('/pylons/buy');
      const { ethereum } = window;
      if (ethereum) {
        const transactionData = data?.tx;
        const hash = await ethereum.request({
          method: 'eth_sendTransaction',
          params: [
            { ...transactionData, nonce: transactionData?.nonce?.toString() },
          ],
        });
        await web3Util.waitForTransaction(hash);
        return hash;
      }
      return null;
    },
    {
      onSuccess: () => {
        window.location.href = '/studio/mypylon';
        queryClient.invalidateQueries('queuestatus');
      },
    }
  );

  return (
    <div className="mt-5 justify-center px-6 md:justify-start md:px-0">
      <ol className="list-decimal">
        <li>{t('Transfer 0.1 ETH to our wallet via MetaMask.')}</li>
        <li>
          {t(
            'Once the transfer has been confirmed by us, we transfer the META PYLON to your wallet.'
          )}
        </li>
      </ol>

      <section className="mt-4 grid grid-flow-col grid-rows-1 items-center justify-center gap-5 md:justify-start">
        <button
          onClick={async () => {
            if (!loggedIn) {
              await connectMetamask.mutateAsync();
            }
            await buyPylons.mutateAsync();

            queryClient.resetQueries();
          }}
          className={twMerge(
            'flex items-center mt-5 min-w-[300px] w-[270px] md:w-full h-[80px] cursor-pointer justify-center rounded border-b-2 border-r-2 border-black-500 bg-black-400 px-3 text-center text-base font-hiraginoBold text-white hover:bg-opacity-[0.7]',
            'bg-yellow-500 cursor-pointer hover:shadow-lg hover:bg-yellow-600 text-black-600'
          )}
        >
          {buyPylons.isLoading || connectMetamask.isLoading ? (
            <FaSpinner className="animate-spin" />
          ) : (
            <div className="py-4 px-2 text-center font-hiraginoBold text-[20px] capitalize md:px-3">
              {loggedIn ? (
                <h3>{t('Buy Now')}</h3>
              ) : (
                <h3>{t('Connect to Metamask and Buy Now')}</h3>
              )}
            </div>
          )}
        </button>
      </section>

      {/* {queueData && queueData.pylonNumber >= 1 && (
        <>
          <p className="pt-8">Share your purchase!</p>
          <div className="flex flex-wrap space-x-2 pt-4 ">
            <FacebookShareButton
              url={'https://metapylon.io/'}
              hashtag={'#METAPYLON'}
            >
              <FacebookIcon size={48} />
            </FacebookShareButton>

            <TwitterShareButton
              url={'https://metapylon.io/'}
              title={'I just bought META PYLON!'}
              hashtags={['NFTs', 'METAPYLON']}
            >
              <TwitterIcon size={48} />
            </TwitterShareButton>
          </div>

          <p className="pt-12 pb-8">Check the META PYLON you have purchased</p>
          <Link href="/studio/mypylon">
            <a className="inline-flex w-[270px] max-w-sm cursor-pointer items-start justify-center rounded border-b-2 border-r-2 border-black-500 bg-yellow-500 py-4 px-6 text-center text-lg hover:bg-opacity-[0.7] md:w-full">
              Go To My PYLON
            </a>
          </Link>
        </>
      )} */}
    </div>
  );
};
