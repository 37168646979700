import { Shimmer } from 'react-shimmer';

interface IStickerCardShimmer {
  width?: any;
  height?: any;
}

export const StickerCardShimmer: React.FC<IStickerCardShimmer> = ({
  width,
  height,
}) => (
  <section className="flex w-full max-w-[330px] flex-col px-5 shadow-sm hover:shadow-xl md:px-0 ">
    <Shimmer className="!w-full" width={width ?? 400} height={height ?? 310} />
  </section>
);
