import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useQueryClient } from 'react-query';
import { Shimmer } from 'react-shimmer';

import { useMetaMask, useStickers } from '@/hooks';

import { CustomError, Pagination } from '../../atoms';
import { StickerCard } from '../../molecules/StickerCard';

export const HiddenNFTs: React.FC = () => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState(0);
  const queryClient = useQueryClient();
  const itemsPerPage = 10;
  const router = useRouter();

  const { data, isLoading, isFetching } = useStickers(
    activePage * itemsPerPage,
    itemsPerPage,
    'hidden'
  );
  const { updateStickers } = useMetaMask();

  const handleNFTRefresh = async () => {
    await updateStickers.mutateAsync(true);
    queryClient.invalidateQueries(['stickers']);
  };

  return (
    <>
      <div className="my-10 flex flex-col items-center justify-between md:flex-row">
        <div className=" flex flex-col items-center gap-5 md:flex-row">
          <div className=" text-center font-goboldhollow text-[38px] md:text-left">
            {t('Hidden NFTs')}
          </div>
          <button
            disabled={updateStickers.isLoading}
            onClick={handleNFTRefresh}
            className="inline-flex h-[28px] w-[92px] items-center justify-center whitespace-nowrap rounded bg-black-500 px-3 py-1 text-xs font-semibold text-white hover:bg-opacity-[0.7]"
          >
            {updateStickers.isLoading ? (
              <FaSpinner className="animate-spin" />
            ) : (
              t('Refresh')
            )}
          </button>
        </div>
        <div className="flex w-full justify-end  font-hiraginoMedium text-[12px] md:w-auto">
          <button
            className="mt-10 rounded-[6px] border-[1px] border-black-500 py-[5px] px-[20px] md:mt-0"
            onClick={() => router.push('/studio/mypylon')}
          >
            {t('NFT Collections')}
          </button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
        {isLoading || isFetching ? (
          <>
            {[...Array(10)].map((_, i) => (
              <Shimmer className={'!w-full'} height={246} width={246} key={i} />
            ))}
          </>
        ) : (
          data?.stickers?.map((sticker, i) => (
            <StickerCard key={i} sticker={sticker} type="unhide" />
          ))
        )}
      </div>
      {!isLoading && (
        <>
          {Number(data?.pages) > 0 ? (
            <Pagination
              pages={data?.pages ? Number(data.pages) : 1}
              activePage={activePage}
              setActivePage={(page: number) => setActivePage(page)}
            />
          ) : (
            <div className="mb-6 px-2 text-center md:px-0 md:text-left">
              <CustomError
                errorMessage={t(
                  'Your Hidden NFTs is empty. NFTs will appear here in case we consider them to be spam'
                )}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
