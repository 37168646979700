import Image from 'next/image';

export const LandingFooter: React.FC = () => {
  return (
    <>
      <footer className="mb-16 flex flex-col items-center justify-center text-center">
        <div className="mt-12">
          <Image
            src="/assets/landing/footer.png"
            alt="footer"
            width="190px"
            height="169px"
          />
        </div>
        <div className="mt-10 text-sm tracking-tighter text-black-600">
          &copy; Copyright 2022 - META PYLON • Made with 🖤 in Tokyo.
        </div>
      </footer>
    </>
  );
};
