import { useQuery } from 'react-query';

import { API } from '@/utils/api';

interface Mint {
  mintCount: string;
}

export const useMint = () => {
  return useQuery<Mint, Error>('mints', async () => {
    const response = await API.get<Mint>(`/mints`);
    return response?.data;
  });
};
