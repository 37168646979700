import Image from 'next/image';
import Link from 'next/link';

import { useAuth } from '@/context/AuthContext';
import { useWeb3Accounts } from '@/context/Web3Accounts';
import { useMetaMask } from '@/hooks';

import { ConnectWalletBtn, LanguageSwitcher } from '../../atoms';
import { SocialIcons } from '../../molecules';

export const MintNavBar: React.FC = () => {
  const { connectMetamask } = useMetaMask();
  const { hasAccounts } = useWeb3Accounts();
  const { hasToken } = useAuth();
  const loggedIn = hasToken && hasAccounts;
  return (
    <>
      <nav className="container m-auto rounded py-2.5 sm:px-4">
        <div className="container mx-auto mt-4 flex flex-wrap items-center justify-center gap-5 text-center md:justify-between">
          <Link href="/">
            <a>
              <Image
                src="/assets/landing/meta-pylon-logo.png"
                alt="metapylon logo"
                width="230px"
                height="18px"
                className="cursor-pointer"
              />
            </a>
          </Link>
          <div className="w-full md:block md:w-auto" id="mobile-menu">
            <ul className="flex flex-col items-center justify-center gap-x-3 gap-y-5 md:mt-0 md:flex-row md:space-x-2 md:text-sm md:font-medium">
              <li>
                {!loggedIn && (
                  <ConnectWalletBtn
                    loading={connectMetamask.isLoading}
                    disabled={connectMetamask.isLoading}
                    onClick={async () => {
                      await connectMetamask.mutateAsync();
                    }}
                  />
                )}
              </li>
              <div className="flex items-center space-x-5">
                <LanguageSwitcher className="border-gray-600" />
                <li>
                  <SocialIcons
                    className="mt-0"
                    boatLink="https://opensea.io/collection/metapylonnft"
                    discordLink="https://discord.com/invite/mTMEwUNYEe"
                    twitterLink="https://twitter.com/MetaPylon"
                  />
                </li>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
