import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

interface StudioTabBarProps {
  currentTab: string;
}

export const StudioTabBar: React.FC<StudioTabBarProps> = ({ currentTab }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false as Boolean);
  const { t } = useTranslation();
  return (
    <>
      <section className="hidden flex-col sm:block md:visible ">
        <div className="w-full border-t border-gray-400"></div>
        <div className="mx-auto hidden w-full max-w-[1200px] flex-row justify-between px-5 md:flex">
          <div className="flex w-full flex-row gap-8">
            <div
              className={`${
                currentTab === 'pylon' ? 'active' : null
              } flex flex-row gap-8 py-5 font-semibold uppercase`}
            >
              <div className="font-hiraginoBold text-[13px] font-semibold text-black-500">
                <Link href="/studio/mypylon">{t('my pylon')}</Link>
              </div>
            </div>
            <div
              className={`${
                currentTab === 'gallery' ? 'active' : null
              } flex flex-row gap-8 py-5 font-semibold uppercase`}
            >
              <div className="text-[13px] text-black-500">
                <Link href="/studio/gallery">{t('Gallery')}</Link>
              </div>
            </div>
            <div
              className={`${
                currentTab === 'stickerpacks' ? 'active' : null
              } flex flex-row gap-8 py-5 font-semibold uppercase`}
            >
              <div className="text-[13px] text-black-500">
                <Link href="/studio/stickerpacks">{t('Sticker Packs')}</Link>
              </div>
            </div>
            <div className="grow"></div>
            <div
              className={`flex flex-row gap-8 py-5 font-semibold uppercase text-[12px] text-black-500`}
            >
              <a
                href="https://wooltrakey.notion.site/META-PYLON-FAQ-a737339a020a40b2b318ea104699baa8"
                target="_blank"
                rel="noreferrer"
              >
                {t('HOW TO USE STUDIO')}
              </a>
            </div>
          </div>
        </div>

        <div className="w-full border-t border-gray-400"></div>
      </section>

      <div className="flex flex-wrap items-center justify-between bg-gray-200 p-6 md:hidden md:bg-white">
        <div className="md:hidden">
          <button
            className="flex rounded border border-black-500 px-3 py-2 text-black-500"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            <svg
              className="h-6 w-4 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
            <span className="px-3 uppercase">{t('menu')}</span>
          </button>
        </div>
        {showMobileMenu && (
          <div className="block w-full grow  text-xl md:text-5xl lg:flex lg:w-auto lg:items-center">
            <div className="flex cursor-pointer flex-col gap-8 py-5 font-semibold uppercase">
              <div className="text-gray-700">
                <Link href="/studio/mypylon">{t('my pylon')}</Link>
              </div>
              <div className="text-gray-700">
                <Link href="/studio/gallery">{t('gallery')}</Link>
              </div>
              <div className="text-gray-700">
                <Link href="/studio/stickerpacks">{t('Sticker Packs')}</Link>
              </div>
              <div className="text-gray-700">
                <a
                  href="https://wooltrakey.notion.site/META-PYLON-FAQ-a737339a020a40b2b318ea104699baa8"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('HOW TO USE STUDIO')}
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
