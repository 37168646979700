import Link from 'next/link';
import { useTranslation } from 'next-i18next';

export const Presale = () => {
  const { t } = useTranslation();

  return (
    <section className="mx-[12px] mt-[60px] flex flex-col items-center text-black-400 xsm:mx-[24px] xsm:mt-[80px] sm:mx-[60px] sm:mt-[90px] md:mx-[80px] lg:flex-row-reverse lg:justify-center">
      <div className="flex flex-col items-center justify-center lg:ml-6 lg:flex lg:flex-col lg:items-start lg:justify-start">
        <h3 className="mb-5 font-goboldRegular text-2xl sm:mb-8 sm:text-4xl md:text-[58px] lg:mb-1 lg:flex-1 lg:text-[48px]">
          {t('Pre-Sale')} Nov
        </h3>
        <p className="mb-5 text-center font-hiragino text-sm leading-5 sm:mb-8 sm:text-lg sm:leading-6 md:text-2xl md:leading-8 lg:mb-0 lg:whitespace-nowrap lg:text-sm">
          {t(
            'A limited number of 2,000 META PYLON NFTs will be available for sale to WL owners'
          )}
        </p>
      </div>

      <Link href="/mint">
        <div className="flex h-[60px] w-full max-w-[420px] cursor-pointer items-center justify-center rounded-[10px] bg-black-400 text-white sm:h-[80px] ">
          <img className="mr-1" src="assets/landing/fox.svg" alt="meta-mask" />
          <p className="font-hiraginoBold text-sm sm:text-[18px]">
            {t('Buy Now')}!
          </p>
        </div>
      </Link>
    </section>
  );
};
