import { useTranslation } from 'next-i18next';
import React from 'react';

import { AccordianFAQs } from '@/components/atomic/molecules/AccordianFAQs';

export const FAQ: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="pt-0">
        <div className="my-5 flex flex-col-reverse items-center font-goboldRegular md:my-10 md:flex-row">
          <span className="z-20 -mt-4 text-xl sm:-mt-10 sm:text-5xl md:mt-5 md:mr-2 lg:text-5xl">
            {t('Frequently Asked Questions')}
          </span>
          <span className="-scale-x-100">
            <img
              src="/assets/landing/faqPylon.png"
              alt="faqPylon"
              className="mr-7 w-12 sm:mr-12 sm:w-28 md:mr-0 md:w-24"
            />
          </span>
        </div>
        <div className="mx-2 mt-7 flex flex-col items-center justify-center md:mx-20 lg:mx-28">
          <AccordianFAQs />
        </div>
      </section>
    </>
  );
};
