/* eslint-disable tailwindcss/no-custom-classname */
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import { CustomModal } from '../../atoms';

export const Services: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <CustomModal
        visible={visible}
        bgColor="white"
        onClose={() => setVisible(false)}
      >
        <div className="z-100 p-[10px]">
          <video className="w-full max-w-[800px]" controls>
            <source src="/assets/landing/service.mp4" type="video/mp4" />
            {t('Your browser does not support HTML video.')}
          </video>
        </div>
      </CustomModal>
      <div className="mt-[200px] w-full bg-gray-300 py-10">
        <div className="absolute right-0 mt-[-220px] md:right-[20px] md:mt-[-200px] lg:right-[10%]">
          <Image
            src="/assets/landing/note_mp.png"
            alt=""
            height={350}
            width={450}
            objectFit="contain"
          />
        </div>
        <section className="mx-5 grid grid-cols-1 items-end gap-x-[40px] md:grid-cols-2 lg:mx-20">
          <div className="mt-[80px] md:mt-[30px] lg:mt-0">
            <Image
              src="/assets/services-logo.svg"
              width="335"
              height="221"
              alt="metamask"
            />
            <p className="font-[15px] my-5 whitespace-pre-line text-black-500">
              {t(
                "META PYLON STUDIO is the world's first flexible sticker customization system (patent acquired). The user can customize their NFT on the META MASK by The user can change the tilt, size and overlapping order of the NFTs. The size and order of overlap can be specified intuitively. In addition, the customised visuals also change the appearance of the NFTs. And are reflected in realtime on-chain."
              )}
            </p>
            <a
              href={'/studio/mypylon'}
              className="mt-4 inline-flex h-[76px] w-full max-w-[386px] items-center justify-center rounded border-[1px] border-black-600 bg-yellow-500 py-3 px-1 text-center text-sm text-[#000101] hover:bg-opacity-[0.7]"
            >
              <span className="ml-3 font-sans text-xs font-semibold  xs:text-sm">
                {t('Go to META PYLON STUDIO')}
              </span>
            </a>
          </div>
          <div>
            <p className="mt-[20px] mb-1 font-sans text-[24px] text-black-500 md:mt-[150px]">
              {t('Customize it with your own unique flair!')}
            </p>
            <p className="font-[15px] mt-[20px] whitespace-pre-line text-black-500">
              {t(
                'That feeling of sticking your favourite stickers on your desk, chest of drawers or pencil case.Your sense of style at a glance.'
              )}
            </p>
            <p className="mt-[20px] mb-1 font-sans text-[24px] text-black-500">
              {t('it is a “META” Sticker')}
            </p>
            <p className="mt-[20px] text-[15px] text-black-500">
              {t(
                'Just a sticker? No. META Stickers can be Variable angles, stacking order and size. For example. You can stretch one sticker to a large size to create an all-over pattern.'
              )}
            </p>
            <button
              onClick={() => setVisible(true)}
              className="mt-4 inline-flex min-h-[76px] w-full max-w-[480px] items-center justify-center rounded bg-black-500 px-3 text-center text-sm text-white hover:bg-opacity-[0.7]"
            >
              <div className="relative flex items-center justify-center">
                <Image
                  src="/assets/landing/service-thumbnail.png"
                  width="100px"
                  height="50px"
                  alt="service"
                />
                <img
                  src="/assets/landing/play-black.png"
                  width="16px"
                  height="16px"
                  alt="service"
                  className="absolute"
                />
              </div>
              <span className="ml-5 font-sans text-xs text-white xs:text-sm">
                {t('How to META PYLON STUDIO WATCH VIDEO')}
              </span>
            </button>
          </div>
        </section>
      </div>
      <div className="w-full bg-yellow-500">
        <section className="mx-5 grid grid-cols-1 items-end gap-x-[40px] md:grid-cols-2 lg:mx-20">
          <div className="pt-[100px] pb-[40px] text-black-500 md:pb-[100px]">
            <div className="flex justify-center md:justify-start">
              <Image
                src="/assets/landing/meta-sticker.svg"
                width="333"
                height="137"
                alt="meta sticker"
              />
            </div>
            <p className="font-[15px] mb-5 mt-[50px] whitespace-pre-line">
              {t(
                `META STICKER is a series of NFT sticker packs distributed as a "sticker pack that you only know what's inside when you open it", in collaboration with various creators in addition to the original META PYLON Sticker.`
              )}
            </p>
            <div className="my-5 text-[24px]">{t('Features')}</div>
            <ul className="font-[15px] pl-[10px]">
              <li className="mt-1">
                ・
                {t(
                  'The sticker packs can be cut out in the shape of a sticker and the transparent data can be pasted on them.'
                )}
              </li>
              <li className="mt-1">
                ・
                {t(
                  'The unopened pack can be kept for future use, or opened and pasted on.'
                )}
              </li>
              <li className="mt-1">
                ・
                {t(
                  'The collection can be shown off, exchanged and enjoyed by the community.'
                )}
              </li>
              <li className="mt-1">
                ・
                {t(
                  "Linked to the distribution of physical stickers, let's go beyond the dimensional barriers."
                )}
              </li>
            </ul>
            <a
              href={'/studio/stickerpacks'}
              className="mt-7 inline-flex h-[76px] w-full max-w-[386px] items-center justify-center rounded  bg-[#000000] py-3 px-1 text-center text-sm text-yellow-500 hover:bg-opacity-[0.7]"
            >
              <span className="ml-3 font-sans text-xs font-semibold xs:text-sm">
                {t('Go to META STICKER')}
              </span>
            </a>
          </div>
          <div className="flex justify-center">
            <img
              src="/assets/landing/services-sticker.png"
              alt="meta_sticker"
              className="h-100% max-h-[700px]"
            />
          </div>
        </section>
      </div>
    </>
  );
};
