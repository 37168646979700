import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';

import { useLoggedIn } from '@/context/useLoggedIn';
import { useMetaMask } from '@/hooks';

export const MintDiscordSection: React.FC = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const { connectMetamask } = useMetaMask();
  const { loggedIn } = useLoggedIn();
  const router = useRouter();

  const connectAndEnterStudio = async () => {
    if (!loggedIn) {
      setLoading(true);
      try {
        await connectMetamask.mutateAsync();
        router.push('/studio');
      } catch {
        setLoading(false);
      }
    } else {
      router.push('/studio');
    }
  };

  return (
    <section className="relative mt-20 flex w-full flex-col items-center justify-center gap-10 bg-yellow-500 py-20 px-5 text-center md:flex-row md:py-5 md:text-left">
      <p className="max-w-[400px] font-semibold leading-6">
        {t(
          'META PYLON STUDIO was created to customize your Pylon to be unique and creative with your NFT collections!'
        )}
      </p>
      <div className="relative h-20 w-12 min-w-[48px]">
        <Image
          src="/assets/landing/cone.png"
          alt="metapylon cone"
          layout="fill"
        />
      </div>
      <a
        onClick={connectAndEnterStudio}
        className="inline-flex min-h-[60px]  w-full max-w-[349px] items-center justify-center rounded bg-black-500 py-3 px-6 text-xl text-yellow-500 hover:cursor-pointer hover:bg-opacity-[0.7] lg:ml-5"
      >
        {loading ? (
          <FaSpinner className="animate-spin" />
        ) : (
          <span className="ml-3 text-center">
            {loggedIn
              ? t('Enter Studio')
              : t('Connect to Wallet and Enter Studio')}
          </span>
        )}
      </a>
    </section>
  );
};
