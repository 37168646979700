import Head from 'next/head';

interface HeadPropTypes {
  title?: string;
  description?: string;
}

export const MetaHead: React.FC<HeadPropTypes> = ({ title, description }) => (
  <Head>
    {/* need to add more SEO tags here */}
    <title>{`${title} - ${description}`}</title>
    <meta name="description" content={description} />
    <link rel="icon" href="/assets/favicon.png" />
    <meta property="og:type" content="website" />
    <meta name="og:title" property="og:title" content={title} />
    <meta
      name="og:description"
      property="og:description"
      content={description}
    />
  </Head>
);

MetaHead.defaultProps = {
  title: 'META PYLON',
  description:
    'An exclusive platform for META PYLON Holder to customize META PYLON NFT.',
};
