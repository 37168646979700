import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useMutation, useQueryClient } from 'react-query';

import { ConnectWallet } from '@/components/atomic/sections/ConnectMetamask';
import { useLoggedIn } from '@/context/useLoggedIn';
import {
  useCollection,
  useCollections,
  useCollectionStickers,
} from '@/hooks/studio/useCollection';
import { API } from '@/utils/api';

import { StickerPacksHeading } from '../../atoms';
import { StickerCardShimmer } from '../../atoms/StickerCardShimmer';
import {
  Draggable,
  StickerCollectionModal,
  StickersPackModal,
} from '../../molecules';
import { StickersTabBar } from '../StickersTabBar';

interface StickerPack {
  amount: string;
  cover_image: string;
  created_at: string;
  description: string;
  id: string;
  image: string;
  is_public: boolean;
  label: string;
  mouseover_image: string;
  name: string;
  order: number;
  sample_image: string;
  status: string;
  updated_at: string;
  limit: number;
  sale_packs: number;
  sticker_per_pack: number;
  total_packs: number;
  total_stickers: number;
}

interface User {
  id: string;
  metamask_id: string;
}

interface Collection {
  Pack: StickerPack;
  User: User;
  id: string;
  is_opened: boolean;
  pack_id: string;
  purchased_at: string;
  user_id: string;
}

export const StickerCollectionSection: React.FC = () => {
  const { t } = useTranslation();

  const { loggedIn } = useLoggedIn();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedSticker, setSelectedSticker] = useState<string>();

  const router = useRouter();
  const { id } = router.query;

  const { data, isLoading } = useCollections();
  const { data: stickerCollection, isLoading: stickerCollectionLoading } =
    useCollectionStickers();

  const { data: selectedPack, isLoading: stickerLoading } = useCollection(
    id?.toString()
  );

  const queryClient = useQueryClient();
  const syncPacks = useMutation(async () => {
    await API.get('/packs/sync');
    queryClient.invalidateQueries('mycollections');
  });
  const syncPackStickers = useMutation(async () => {
    await API.get('/my-collection/stickers/sync');
    queryClient.invalidateQueries('mycollections-stickers');
  });

  useEffect(() => {
    setModalVisible(!!id);
  }, [id]);

  const handleCloseModal = () => {
    setModalVisible(false);
    router.push('/studio/stickerpacks/mycollection', undefined, {
      scroll: false,
    });
  };

  return (
    <>
      <StickerCollectionModal
        visible={modalVisible}
        setVisible={setModalVisible}
        data={stickerCollection?.find((item) => item.id === selectedSticker)}
      />

      {selectedPack && (
        <StickersPackModal
          data={{
            ...selectedPack.Pack,
            purchased_at: selectedPack.purchased_at,
            purchased_count: selectedPack.purchased_count,
            token_id: selectedPack.token_id,
            collection_id: selectedPack.id,
          }}
          loading={stickerLoading}
          visible={modalVisible}
          setVisible={() => handleCloseModal()}
          type={selectedPack && selectedPack.is_opened ? 'opened' : 'open'}
        />
      )}

      <div className="px-5 pt-12 pb-6 font-goboldhollow text-4xl uppercase md:px-0">
        {t('Sticker Packs')}
      </div>

      <StickersTabBar currentTab="mycollection" />

      {loggedIn ? (
        <StickerPacksHeading
          title={t('Your META STICKER COLLECTION')}
          subTitle={t(
            'Get META STICKER to make your META PYLON look super cooler!!'
          )}
        />
      ) : (
        <div className="mx-auto w-full max-w-[1200px] px-5">
          <ConnectWallet />
        </div>
      )}

      <>
        <div className="flex items-center px-5 text-left font-sans text-2xl font-light md:px-0">
          <div>{t('My PACKS')}</div>
          <button
            disabled={syncPacks.isLoading}
            onClick={() => syncPacks.mutateAsync()}
            className="ml-4 inline-flex h-[28px] w-[92px] items-center justify-center whitespace-nowrap rounded bg-black-500 px-3 py-1 text-xs font-semibold text-white hover:bg-opacity-[0.7]"
          >
            {syncPacks.isLoading ? (
              <FaSpinner className="animate-spin" />
            ) : (
              t('Refresh')
            )}
          </button>
        </div>
        {data && data.length === 0 ? (
          <div className="mb-24 px-5 md:px-0">
            <div className="my-4 pt-1 font-sans text-[14px] font-light text-[#797979] md:text-[15px]">
              {t('You do not own a pack. Get one now!')}
            </div>
            <a
              href={'/studio/stickerpacks'}
              className="mt-1 inline-flex h-[60px] w-full min-w-[240px] items-center justify-center rounded border-b-2 border-r-2 bg-yellow-500 py-2 text-center  font-goboldRegular text-[22px] hover:bg-opacity-[0.7] md:w-[170px]"
            >
              {t('SEE PACKS')}
            </a>
          </div>
        ) : (
          <div className="px-5 md:px-0">
            <Draggable className="custom-scrollbar mt-5 w-[100%]">
              {isLoading ? (
                <div className=" container mx-auto grid grid-cols-1 gap-2 pt-5 md:grid-cols-2 md:px-0 lg:grid lg:grid-cols-3 lg:gap-20 lg:space-y-0 xl:grid-cols-4  2xl:gap-40">
                  {[...Array(6)].map((_, i) => (
                    <StickerCardShimmer key={i} />
                  ))}
                </div>
              ) : null}
              {data &&
                data.map((pack: Collection, index: number) => {
                  return (
                    <div
                      onClick={() => {
                        router.push(
                          {
                            pathname: '/studio/stickerpacks/mycollection',
                            query: { id: pack.pack_id },
                          },
                          undefined,
                          { scroll: false }
                        );
                      }}
                      key={index}
                      className={`relative mr-20 max-w-[190px] cursor-pointer overflow-visible text-center last:mr-0`}
                    >
                      <img
                        src={
                          pack.is_opened
                            ? pack.Pack.mouseover_image
                            : pack.Pack.image
                        }
                        alt="sticker pack"
                        className={`relative h-[277px] min-w-[190px] overflow-visible rounded-sm hover:opacity-[0.9]  ${
                          pack.is_opened
                            ? 'h-[289px] brightness-[.50]'
                            : 'mt-[14px]'
                        }`}
                        draggable="false"
                      />
                      <div className="mt-2 mb-1 px-5 font-hiraginoBold uppercase">
                        {pack.Pack.name}
                      </div>
                      {pack.is_opened ? (
                        <p className="absolute top-[30%] w-full font-goboldRegular text-2xl uppercase text-white">
                          {t('OPENED')}
                        </p>
                      ) : (
                        <div className="absolute top-[30%]  flex w-full justify-center">
                          <p className="bg-[#fff000] px-3 font-goboldRegular text-xl uppercase text-black-600">
                            {t('open pack')}
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}
            </Draggable>
          </div>
        )}
      </>

      <div className="flex items-center p-5 text-left font-sans text-2xl font-light md:px-0">
        <div>{t('My STICKERS')}</div>
        <button
          disabled={syncPackStickers.isLoading}
          onClick={() => syncPackStickers.mutate()}
          className="ml-4 inline-flex h-[28px] w-[92px] items-center justify-center whitespace-nowrap rounded bg-black-500 px-3 py-1 text-xs font-semibold text-white hover:bg-opacity-[0.7]"
        >
          {syncPackStickers.isLoading ? (
            <FaSpinner className="animate-spin" />
          ) : (
            t('Refresh')
          )}
        </button>
      </div>
      {stickerCollection && stickerCollection.length > 0 ? (
        <div className="grid grid-cols-2 gap-8 px-5 pb-12 md:grid-cols-4 md:px-0">
          {stickerCollectionLoading ? (
            <div className=" container mx-auto grid grid-cols-1 gap-2 pt-5 md:grid-cols-2 md:px-0 lg:grid lg:grid-cols-3 lg:gap-20 lg:space-y-0 xl:grid-cols-4  2xl:gap-40">
              {[...Array(12)].map((_, i) => (
                <StickerCardShimmer key={i} />
              ))}
            </div>
          ) : (
            stickerCollection?.map((sticker, index) => {
              return (
                <div key={index} className="flex w-full justify-center">
                  <img
                    src={sticker.image_url}
                    alt="sticker pack"
                    className="w-full cursor-pointer rounded-sm hover:opacity-[0.9]"
                    onClick={() => {
                      setSelectedSticker(sticker.id);
                      setModalVisible(true);
                    }}
                  />
                </div>
              );
            })
          )}
        </div>
      ) : (
        <div className="px-5 font-sans text-[14px] font-light text-[#797979] md:px-0  md:text-[15px]">
          {t('You do not have any stickers. Open packs to get stickers.')}
        </div>
      )}
    </>
  );
};
