import Image from 'next/image';
import { useTranslation } from 'next-i18next';

export const AboutSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="relative max-w-[100vw] overflow-x-clip">
      <video
        className="absolute top-20 right-[-200px] hidden h-[80%] border-2 object-cover  lg:block lg:h-full xl:right-[-250px]"
        width="80%"
        autoPlay
        loop
        muted
      >
        <source src="/assets/landing/lpPylon.mp4" type="video/mp4" />
      </video>
      <div className="relative mx-0 mt-[64px] bg-yellow-700 pb-10 pt-[54px] text-center md:mt-28 lg:ml-[57px] lg:max-w-[55%] lg:py-[120px] lg:px-[123px]">
        <div className="absolute top-8 left-2 text-[180px] leading-10 md:top-12 md:text-[220px] lg:top-16 lg:text-[300px]">
          “
        </div>

        <div className="absolute bottom-8 right-2 rotate-180 text-[180px] leading-10 md:bottom-12 md:text-[220px] lg:bottom-20 lg:text-[300px]">
          “
        </div>

        <div className="px-20 md:px-7 lg:pb-10">
          <Image
            src="/assets/landing/mp_yoko.svg"
            width="333px"
            height="135px"
            alt="logo"
            className="px-5"
          />
        </div>

        <p className="mt-8 whitespace-pre-line px-6 text-left font-hiragino leading-6  md:px-16 md:text-[30px] md:leading-[42px] lg:px-0 lg:text-[15px] lg:leading-[24px]">
          {t(
            `"META PYLON" is both an individual and a whole. It is information and symbol. "META PYLON" is the monolith of our time, and the human beings who come into contact with it will undergo a new evolution. There are various cultures. We have been moving back and forth between these cultures and reconstructing the world in which we should live. And within that small world, we have used "stickers" as a tool to assert ourselves. They collect them in large quantities and sometimes post them around town as messages, and sometimes as a way to show how good their taste is. Sometimes they stick them on their laptops to show how sophisticated they are. To put them up is a status. Not removing them is status. The very existence of the sticker is status. Now, let's play around in the world of Metaverse. Let's put the new sticker called "NFT" all over "META PYLON"! Let's assert ourselves! Let's mount them all! META PYLON is a status itself!`
          )}
        </p>
      </div>
    </section>
  );
};
