import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { FaSpinner } from 'react-icons/fa';

import { useMyPylons } from '@/hooks/studio/usePylons';
import { usePylonSync } from '@/hooks/studio/usePylonSync';

import {
  CustomError,
  CustomIcon,
  PylonCard,
  PylonCardShimmer,
} from '../../atoms';

export const StudioPylonSection: React.FC = () => {
  const { data, error, isLoading, isError } = useMyPylons();
  const refreshPylon = usePylonSync();
  const { t } = useTranslation();

  if (isError) return <CustomError errorMessage={error.message} />;
  return (
    <>
      <div className="mt-12 flex flex-col items-center gap-5 md:flex-row">
        <div className=" text-center font-goboldhollow text-4xl uppercase md:text-left">
          {t('my pylon')}
        </div>
        <button
          disabled={refreshPylon.isLoading}
          onClick={() => refreshPylon.mutate()}
          className="inline-flex h-[28px] w-[92px] items-center justify-center whitespace-nowrap rounded bg-black-500 px-3 py-1 text-xs font-semibold text-white hover:bg-opacity-[0.7]"
        >
          {!refreshPylon.isLoading && t('Refresh')}
          {refreshPylon.isLoading && <FaSpinner className="animate-spin" />}
        </button>
      </div>

      {isLoading ? (
        <section className="flex flex-col flex-wrap items-center gap-3  pt-8 md:justify-start lg:flex-row">
          {[...Array(6)].map((_, i) => (
            <PylonCardShimmer key={i} />
          ))}
        </section>
      ) : (
        <>
          {data?.length ? (
            <>
              <section className="flex flex-col flex-wrap items-center gap-3  pt-8 md:flex-row md:justify-start">
                {data?.map((pylon) => {
                  const cdnImageUrl = pylon?.mint_data?.animation_url;
                  return (
                    <PylonCard
                      key={pylon.id}
                      id={pylon.id}
                      name={pylon.name.replace('PYLON ', 'PYLON #')}
                      isEditable={pylon.is_designed}
                      modelUrl={pylon.model_url}
                      animationUrl={pylon?.mint_data?.animation_url}
                      mintStatus={pylon.mint_status}
                      imageUrl={
                        pylon?.mint_data?.animation_url
                          ? cdnImageUrl
                          : '/assets/landing/conebag.png'
                      }
                      updatedAt={pylon.updated_at}
                    />
                  );
                })}
              </section>
              <div className="mt-8 flex flex-col items-center justify-center gap-5 md:flex-row md:justify-start">
                {(!data?.length || data?.length < 3) && (
                  <Link href="/mint">
                    <a className="inline-flex items-center rounded bg-yellow-300 py-2 px-5 text-xl hover:bg-opacity-[0.7]">
                      <img
                        className="mr-1"
                        src="/assets/landing/fox.svg"
                        alt="meta-mask"
                      />
                      <p className="text-sm sm:text-lg md:text-2xl">
                        {t('Buy More PYLON')}
                      </p>
                    </a>
                  </Link>
                )}

                <a
                  href="https://discord.com/invite/mTMEwUNYEe"
                  className="inline-flex items-center rounded bg-yellow-300 py-3.5 px-5 text-xl hover:bg-opacity-[0.7]"
                >
                  <CustomIcon type="discord" />
                  <span className="pl-2 text-base font-semibold uppercase">
                    {t('Join Our Discord')}
                  </span>
                </a>
              </div>
            </>
          ) : (
            <div className="mt-5 flex flex-col justify-start gap-9 text-center md:flex-row">
              <div>
                <Image
                  src="/assets/landing/my-pylon-left.png"
                  width="235px"
                  height="216px"
                  alt="mypylon"
                />
              </div>
              <div className="flex flex-col justify-center px-6">
                <span className="text-xl font-light">
                  {t('There’s no META PYLON NFT in your wallet')} 😔
                </span>
                <span className="pt-3 font-light text-gray-500">
                  {t('You need to mint your first META PYLON NFT through')}
                </span>

                <div className="mt-5 flex flex-row justify-center gap-5 md:justify-start">
                  <Link href="/mint">
                    <div className="inline-flex items-center rounded bg-yellow-300 py-2 px-5 text-xl hover:bg-opacity-[0.7]">
                      <img
                        className="mr-1"
                        src="/assets/landing/fox.svg"
                        alt="meta-mask"
                      />
                      <p className="text-sm sm:text-lg md:text-2xl">
                        {t('Buy a PYLON')}
                      </p>
                    </div>
                  </Link>

                  <a
                    href="https://discord.com/invite/mTMEwUNYEe"
                    className="inline-flex items-center rounded bg-yellow-300 py-2 px-5 text-xl hover:bg-opacity-[0.7]"
                  >
                    <CustomIcon type="discord" />
                    <span className="pl-2 text-base font-semibold uppercase">
                      {t('Join Our Discord')}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
