import { StickerImage } from '@wooltrakey/meta-pylon-3d';
import { useQuery } from 'react-query';

import { API } from '@/utils/api';

interface StickerPack {
  amount: string;
  cover_image: string;
  created_at: string;
  description: string;
  id: string;
  image: string;
  is_public: boolean;
  label: string;
  mouseover_image: string;
  name: string;
  order: number;
  sample_image: string;
  status: string;
  updated_at: string;
  limit: number;
  sale_packs: number;
  sticker_per_pack: number;
  total_packs: number;
  total_stickers: number;
}

interface User {
  id: string;
  metamask_id: string;
}

interface Collection {
  Pack: StickerPack;
  User: User;
  id: string;
  is_opened: boolean;
  pack_id: string;
  purchased_at: string;
  user_id: string;
}

export const useCollections = () => {
  return useQuery<Collection[], Error>('mycollections', async () => {
    const response = await API.get<any[]>(`/my-collection`);
    return response?.data;
  });
};

interface CollectionSticker {
  id: string;
  image_url: string;
  sticker: StickerImage & { id: string; contract_address: string };
}

export const useCollectionStickers = () => {
  return useQuery<CollectionSticker[], Error>(
    'mycollections-stickers',
    async () => {
      const response = await API.get<any[]>(`/my-collection/stickers`);
      return response?.data;
    }
  );
};

export const useCollection = (id?: string) => {
  return useQuery<any, Error>(
    ['mycollection', id],
    async (ctx) => {
      const itemId = ctx.queryKey[1] ?? null;
      const response = await API.get<Collection>(`/my-collection/${itemId}`);
      return response.data;
    },
    {
      enabled: !!id,
    }
  );
};
