import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useQueryClient } from 'react-query';
import { Shimmer } from 'react-shimmer';

import { useMetaMask, useStickers } from '@/hooks';

import { CustomError, Pagination } from '../../atoms';
import { StickerCard } from '../../molecules/StickerCard';

export const StudioNFTCollection: React.FC = () => {
  const { t } = useTranslation();

  const [activePage, setActivePage] = useState(0);
  const queryClient = useQueryClient();
  const itemsPerPage = 8;
  const router = useRouter();

  const { data, isLoading, isFetching } = useStickers(
    activePage * itemsPerPage,
    itemsPerPage
  );
  const { updateStickers } = useMetaMask();

  const handleNFTRefresh = async () => {
    await updateStickers.mutateAsync(true);
    queryClient.invalidateQueries(['stickers']);
  };

  return (
    <>
      <div className="mt-16 flex flex-col items-center justify-between md:flex-row">
        <div className=" flex flex-col items-center gap-5 md:flex-row">
          <div className=" text-center font-goboldhollow text-[38px] md:text-left">
            {t('Your NFT Collections')}
          </div>
          <button
            disabled={updateStickers.isLoading}
            onClick={handleNFTRefresh}
            className="inline-flex h-[28px] w-[92px] items-center justify-center whitespace-nowrap rounded bg-black-500 px-3 py-1 text-xs font-semibold text-white hover:bg-opacity-[0.7]"
          >
            {updateStickers.isLoading ? (
              <FaSpinner className="animate-spin" />
            ) : (
              t('Refresh')
            )}
          </button>
        </div>
        <div className="flex w-full justify-end  font-hiraginoMedium text-[12px] md:w-auto">
          <button
            onClick={() => router.push('/hidden')}
            className="mt-10 rounded-[6px] border-[1px] border-black-500 py-[5px] px-[20px] md:mt-0"
          >
            {t('Hidden')} {data?.hidden ? `(${data.hidden})` : ''}
          </button>
        </div>
      </div>

      {(isLoading || Number(data?.pages) > 0) && (
        <div className="container mx-auto grid grid-cols-2 gap-2 px-5 pt-6 text-center md:px-0 lg:grid lg:grid-cols-3 lg:gap-2 lg:space-y-0  xl:grid-cols-4">
          {isLoading || isFetching ? (
            <>
              {[...Array(8)].map((_, i) => (
                <Shimmer
                  className={'!w-full'}
                  height={246}
                  width={246}
                  key={i}
                />
              ))}
            </>
          ) : (
            data?.stickers?.map((item) => (
              <StickerCard key={item.id} sticker={item} />
            ))
          )}
        </div>
      )}

      {!isLoading && (
        <>
          {Number(data?.pages) > 0 ? (
            <Pagination
              pages={data?.pages ? Number(data.pages) : 1}
              activePage={activePage}
              setActivePage={(page: number) => setActivePage(page)}
            />
          ) : (
            <div className="mb-6 px-2 text-center md:px-0 md:text-left">
              <CustomError
                errorMessage={t(
                  'Your NFT collection is empty. You can paste NFT collections on META PYLON as a sticker!'
                )}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
