import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

import { CustomIcon } from '../../atoms';

interface BigSocialIconsProps {
  discordLink: string;
  boatLink: string;
  twitterLink: string;
  className?: string;
}

export const BigSocialIcons: React.FC<BigSocialIconsProps> = ({
  discordLink,
  boatLink,
  twitterLink,
  className,
}) => {
  return (
    <>
      <section
        className={twMerge('mt-8 flex flex-row gap-2 items-center', className)}
      >
        <Link href={discordLink}>
          <a target="_blank" rel="noopener noreferrer">
            <CustomIcon
              type="discord"
              className="h-10 w-10 xsm:h-16 xsm:w-16"
            />
          </a>
        </Link>
        <Link href={boatLink}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="h-8 w-8 xsm:h-12 xsm:w-12"
          >
            <CustomIcon type="opensea" className="h-0 w-0" />
          </a>
        </Link>
        <Link href={twitterLink}>
          <a target="_blank" rel="noopener noreferrer">
            <CustomIcon
              type="twitter"
              className="h-10 w-10 xsm:h-16 xsm:w-16"
            />
          </a>
        </Link>
      </section>
    </>
  );
};
