import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useTranslation } from 'next-i18next';
import { useRef, useState } from 'react';
import Slider from 'react-slick';
import { twMerge } from 'tailwind-merge';

import { LanguageSwitcher } from '../../atoms';
import { SocialIcons } from '../../molecules';
import { BigSocialIcons } from '../../molecules/BigSocialIcons';

export const Header = () => {
  const { t, i18n } = useTranslation();

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const sliderRef = useRef();

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    arrows: false,
    beforeChange: (next: any) => setCurrentSlideIndex(next),
    afterChange: (current: any) => setCurrentSlideIndex(current),
  };
  const backgroundImages = [
    'bg-[url(/assets/landing/background/header-background-small-0.png)] lg:bg-[url(/assets/landing/background/header-background-0.png)]',
    'bg-[url(/assets/landing/background/header-background-small-0.png)] lg:bg-[url(/assets/landing/background/header-background-1.png)]',
    'bg-[url(/assets/landing/background/header-background-small-0.png)] lg:bg-[url(/assets/landing/background/header-background-2.png)]',
    'bg-[url(/assets/landing/background/header-background-small-0.png)] lg:bg-[url(/assets/landing/background/header-background-3.png)]',
  ];
  const marginRight = [
    'right-40 lg:right-[185px]',
    'right-32 lg:right-[170px]',
    'right-24 lg:right-[155px]',
    'right-16 lg:right-[140px]',
  ];
  return (
    <section className="md:bg-yellow-700 md:pr-28">
      <div className="relative">
        <div className="absolute z-10 px-7 pt-32 md:right-24 md:w-[450px] md:px-0 lg:right-32 lg:w-[640px]">
          <img src="/assets/landing/header_logo.png" alt="logo" />
          <p className="mt-8 whitespace-pre-line font-montserratMedium text-sm leading-8 text-black-700 sm:text-xl">
            {t(
              "A NEW STICKER CULTURE in the metaverse world. META PYLON isn't just white traffic cones! Put your NFT collection on, peel it off, and stick it back again! META PYLON is your symbol, your own metaphor!"
            )}
            {i18n.language === 'en' && ' :)'}
          </p>
          <BigSocialIcons
            className="absolute left-[30%] z-10 md:hidden"
            boatLink="https://opensea.io/collection/metapylonnft"
            discordLink="https://discord.com/invite/mTMEwUNYEe"
            twitterLink="https://twitter.com/MetaPylon"
          />
        </div>
        <div className="absolute top-0 left-0 z-10 mt-8 flex w-full items-center justify-between px-28">
          <SocialIcons
            className="mt-0 hidden md:inline-flex"
            boatLink="https://opensea.io/collection/metapylonnft"
            discordLink="https://discord.com/invite/mTMEwUNYEe"
            twitterLink="https://twitter.com/MetaPylon"
          />
          <LanguageSwitcher className="border-gray-600" />
        </div>

        {new Array(backgroundImages.length).fill(null).map((_, index) => {
          return (
            <img
              key={index}
              className={twMerge(
                `absolute bottom-12 h-[35px] w-[26px] cursor-pointer lg:bottom-11 lg:h-[14px] lg:w-[12px] z-10`,
                marginRight[index]
              )}
              onClick={() => {
                setCurrentSlideIndex(index);
                // @ts-ignore
                sliderRef.current!.slickGoTo(index);
              }}
              src={`${
                currentSlideIndex === index
                  ? '/assets/landing/background/cone-active.svg'
                  : '/assets/landing/background/cone-inactive.svg'
              }`}
              alt="cone"
            />
          );
        })}
        <img
          className="absolute right-[60px] bottom-10 z-10 hidden lg:block"
          src="/assets/landing/cone-bar.svg"
          alt="cone-bar"
        />
        <Slider
          {...settings}
          ref={(slider) => {
            // @ts-ignore
            sliderRef!.current = slider;
          }}
        >
          {new Array(backgroundImages.length).fill(null).map((_, index) => {
            return (
              <div
                key={index}
                id="headerBackground"
                className={twMerge(
                  `z-10 h-[1000px] w-full  bg-cover bg-no-repeat md:h-[1334px] lg:h-[771px]`,
                  backgroundImages[index]
                )}
              />
            );
          })}
        </Slider>
      </div>
    </section>
  );
};
