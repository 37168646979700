import { useQuery } from 'react-query';

import { API } from '@/utils/api';

interface StickerPack {
  amount: string;
  cover_image: string;
  created_at: string;
  description: string;
  id: string;
  image: string;
  is_public: boolean;
  label: string;
  mouseover_image: string;
  name: string;
  order: number;
  sample_image: string;
  status: string;
  updated_at: string;
  limit: number;
  sale_packs: number;
  sticker_per_pack: number;
  total_packs: number;
  total_stickers: number;
}

export const useStickerPacks = () => {
  return useQuery<any, Error>('mypylons', async () => {
    const response = await API.get<StickerPack[]>(`/packs`);
    return response?.data;
  });
};

export const useStickerPack = (id?: string) => {
  return useQuery<any, Error>(
    ['mypylon', id],
    async (ctx) => {
      const itemId = ctx.queryKey[1] ?? null;
      const response = await API.get<StickerPack>(`/packs/${itemId}`);
      return response.data;
    },
    {
      enabled: !!id,
    }
  );
};
