import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import { useMint } from '@/hooks/studio/useMint';

import { CustomIcon } from '../../atoms';

const GetNFTText = ({ isShadow = false }: { isShadow?: boolean }) => {
  const { t } = useTranslation();
  return (
    <div
      className={twMerge(
        'relative z-10 font-goboldRegular text-4xl text-black-400 leading-[42px] xsm:text-5xl xsm:leading-[52px] md:text-7xl md:leading-[80px] lg:text-[106px] lg:leading-[120px]  ',
        isShadow &&
          'absolute -top-2 left-[7px] z-[1] text-yellow-700 font-goboldHollowBold'
      )}
    >
      {t('SHOWCASE YOUR NFT')}
    </div>
  );
};

export const ShowCase = () => {
  const { t } = useTranslation();

  const { data } = useMint();
  return (
    <section className="relative flex">
      <div className="w-[60%] flex-1 bg-gray-50 px-2 pb-0 pt-[50px] leading-[80px] md:pt-[110px] md:pb-20 md:pl-8 md:pr-10 lg:absolute lg:-top-16 lg:flex lg:items-start lg:justify-end lg:pr-[100px] lg:pb-10 ">
        <img
          className="absolute top-0 left-0 -z-0 hidden h-[720px] lg:block "
          src="/assets/landing/showcase.png"
          alt="showcase"
        />
        <div className="lg:relative lg:z-10  lg:mt-[0px] lg:flex lg:w-[60%] lg:max-w-[500px] lg:flex-col lg:items-end lg:justify-center">
          <div className="relative">
            <GetNFTText />
            <GetNFTText isShadow />
          </div>
          <div className="flex flex-col gap-[10px]">
            <a
              href="https://discord.com/invite/mTMEwUNYEe"
              className="mt-7 inline-flex h-[40px]  w-[100%] items-center justify-center rounded-[10px] bg-yellow-700 text-xl font-semibold text-black-500 hover:cursor-pointer hover:bg-opacity-[0.7] md:h-[68px] md:w-full lg:h-[80px] "
              target="_blank"
              rel="noreferrer"
            >
              <CustomIcon
                type="discord"
                className="h-6 fill-black-500 md:w-6"
              />
              <span className="ml-1 hidden font-hiraginoBold text-xs xsm:block md:ml-3 md:text-lg">
                META PYLON DISCORD
              </span>
            </a>

            <p className="mt-0 flex max-w-[300px] pb-8 text-center font-hiragino text-[15px] leading-[18px] text-black-400 md:mt-4 md:leading-[24px] lg:mx-8">
              {t(
                'Join our discord community for every updates and new information.'
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="h-full flex-1 text-right lg:flex lg:w-[40%] lg:justify-end">
        <div className="bg-yellow-700 pt-[50px] pb-20 pr-[20px]  xsm:pt-[50px]  xsm:pb-16 md:pr-[97px] md:pb-44 lg:pl-[200px] xl:pl-[30%]">
          <h2 className="sc-500:text-[70px] font-goboldhollow text-[40px]  xsm:text-[64px]  md:text-[84px] lg:text-[125px]">
            MINT
          </h2>
          <h3 className="font-goboldRegular text-[24px] xsm:text-[32px] sm:text-[52px]  md:text-[58px] lg:text-[68px] xl:text-[78px]">
            {data?.mintCount ? data.mintCount : 0}
          </h3>
          <h2 className="font-goboldhollow text-[30px] md:text-[40px]  lg:text-[60px]">
            PYLONS
          </h2>
        </div>
      </div>
    </section>
  );
};
