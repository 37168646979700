import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { LandingFooter } from '../LandingFooter';

export const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <footer className="mb-16 flex flex-col items-center justify-center pt-12 text-center">
        <div className="footerPylonImage">
          <Image
            src="/assets/landing/pylon_footer.png"
            alt=""
            width="80"
            height="200"
          />
          <div className="relative my-0 scale-y-110 font-goboldhollow text-4xl text-gray-800 xss:text-5xl xs:text-6xl sm:text-7xl">
            Coming soon!
            <div className="absolute top-1 right-1 -z-10 w-full font-goboldRegular text-4xl text-[#fff600] xss:text-5xl xs:text-6xl sm:text-7xl">
              <span className="">Coming soon!</span>
            </div>
          </div>
        </div>
        <span className="mt-10 w-full max-w-md">
          {t(
            'Stay tuned and follow our twitter and discord to see updates and artwork sneak peek'
          )}{' '}
          😉
        </span>

        <div className="-mb-12">
          <LandingFooter />
        </div>
      </footer>
    </>
  );
};
