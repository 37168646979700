/* eslint-disable tailwindcss/no-custom-classname */
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { CustomModal } from '../../atoms';

export const LandingServices: React.FC = () => {
  const { t } = useTranslation();

  const router = useRouter();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <CustomModal
        visible={visible}
        bgColor="white"
        onClose={() => setVisible(false)}
      >
        <div className="z-100 p-[10px]">
          <video className="w-full max-w-[800px]" controls>
            <source src="/assets/landing/service.mp4" type="video/mp4" />
            {t('Your browser does not support HTML video.')}
          </video>
        </div>
      </CustomModal>
      <section className="w-full py-20">
        <div className="mx-auto mt-2 flex flex-col items-center px-5 text-center md:flex-row md:justify-between md:space-x-5 md:px-0 md:text-left">
          <div className="w-full">
            <h2 className="text-justify text-xl font-light md:max-w-sm">
              {t(
                'META PYLON Studio are made to help you create unique META PYLON NFT with your own collections or sticker packs'
              )}
            </h2>
            <p className="mt-3 text-left text-sm font-light text-black-200">
              {t('Learn how to use it through our 2 minutes tutorial video')}
            </p>
            <button
              className="mt-4 inline-flex w-full max-w-[220px] items-center justify-center rounded bg-black-500 py-3 px-1 text-center text-sm uppercase text-white hover:bg-black-600 hover:bg-opacity-[0.7]"
              onClick={() => setVisible(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
              >
                <text
                  id="_"
                  data-name="▲"
                  transform="translate(2 7) rotate(90)"
                  fill="#fff"
                  fontSize="14"
                  fontFamily="HiraKakuPro-W6, Hiragino Kaku Gothic Pro"
                  letterSpacing="-0.025em"
                >
                  <tspan x="-7" y="0">
                    ▲
                  </tspan>
                </text>
              </svg>
              <span className="ml-3">{t('Watch Video')}</span>
            </button>
            <button
              className="mt-5 w-full max-w-[220px] whitespace-nowrap rounded bg-yellow-500 py-3 px-9 text-sm hover:bg-yellow-600 hover:bg-opacity-[0.7] sm:block"
              onClick={() => router.push('/studio/mypylon')}
            >
              {t('See Your Collection')}
            </button>
          </div>
          <div className="relative h-[300px] w-full pt-12 sm:my-14 md:my-0 md:block md:h-[400px]">
            <Image
              src="/assets/landing/pc.png"
              className="pl-4 sm:pr-10 lg:pr-32 xl:pl-10"
              alt="pc"
              layout="fill"
              objectFit="contain"
            />
          </div>
        </div>

        <div className="mx-auto mt-2 box-border flex flex-col md:mt-24 md:flex-row md:justify-between md:space-x-5">
          <div>
            <Image
              src="/assets/landing/3.png"
              alt="landing"
              width="621px"
              height="392px"
            />
          </div>
          <div className="flex flex-col justify-center">
            <h2 className="max-w-sm text-justify text-xl font-light">
              {t('Discover META PYLON sticker packs')}
            </h2>
            <p className="mt-3 max-w-md text-sm font-light leading-7 text-black-200">
              {t(
                'Don’t have enough NFT collections? You can discover and buy our exclusive and collaboration with fellow creators and use it on your own META PYLON'
              )}
            </p>
            <button
              className="mt-5 w-full max-w-[250px] rounded bg-yellow-500 py-3 px-9 text-base hover:bg-yellow-600 hover:bg-opacity-[0.7] sm:block"
              onClick={() => router.push('/studio/stickerpacks')}
            >
              {t('See Sticker Packs')}
            </button>
          </div>
        </div>
        <div className="visible mt-12 bg-yellow-500 p-5 pb-12 md:invisible md:h-0 lg:invisible xl:invisible xl:h-0 2xl:invisible 2xl:h-0">
          <h2 className="text-5xl font-bold">oh.Sorry.</h2>
          <p className="pt-4 text-2xl font-light">
            {t(
              'Metapylon is an NFT that can be made on a PC.Please switch to a PC and take on the challenge!'
            )}
          </p>

          <div className="pt-12 text-center">
            <Image
              src="/assets/landing/mobile-pc.png"
              width="350px"
              height="135px"
              alt="mobiletopc"
            />
          </div>
        </div>
      </section>
    </>
  );
};
