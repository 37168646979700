import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { Shimmer } from 'react-shimmer';

import { useGallery } from '@/hooks';
import { Pylon } from '@/types/pylon';

import { CustomError, GalleryCard, Pagination } from '../../atoms';
import { GalleryModal } from '../../molecules';

interface RenderDataProps {
  children?: React.ReactNode;
}

const RenderData: React.FC<RenderDataProps> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="pt-12 text-center font-goboldhollow text-4xl uppercase md:text-left">
        {t('Gallery')}
      </div>
      <div className="container mx-auto space-y-2 px-5 pt-12 md:px-0 lg:grid lg:grid-cols-4 lg:gap-2 lg:space-y-0">
        {children}
      </div>
    </>
  );
};

export const StudioGallerySection: React.FC = () => {
  const [activePage, setActivePage] = useState(0);
  const itemsPerPage = 8;
  const { data, error, isLoading, isError } = useGallery(
    activePage * itemsPerPage,
    itemsPerPage
  );
  const [visible, setVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Pylon>();

  if (isError) return <CustomError errorMessage={error.message} />;

  const populateSelectedItem = async (galleryItem: Pylon) => {
    setSelectedItem(galleryItem);
    setVisible(true);
  };

  return (
    <>
      {visible && (
        <GalleryModal
          visible={visible}
          setVisible={setVisible}
          selectedItemId={selectedItem!.id}
          animationUrl={selectedItem?.mint_data.animation_url ?? ''}
        />
      )}
      <RenderData>
        {isLoading ? (
          <>
            {[...Array(8)].map((_, i) => (
              <Shimmer key={i} width={284} height={320} className="!w-full" />
            ))}
          </>
        ) : (
          <>
            {data?.pylons?.map((galleryItem) => (
              <GalleryCard
                animationUrl={galleryItem.mint_data.animation_url}
                key={galleryItem.id}
                image={'/assets/landing/conebag.png'}
                title={galleryItem.name}
                subTitle="Meta pylon #007"
                price={0.099}
                expiry={'6 days left'}
                likes={galleryItem.likes}
                onClick={() => populateSelectedItem(galleryItem)}
              />
            ))}
          </>
        )}
      </RenderData>
      {!isLoading && (
        <>
          {Number(data?.pages) > 0 ? (
            <Pagination
              pages={data?.pages ? Number(data.pages) : 1}
              activePage={activePage}
              setActivePage={(page: number) => setActivePage(page)}
            />
          ) : (
            <div className="mb-16 flex flex-col items-center justify-center text-center">
              <Image
                src="/assets/landing/pylon_footer.png"
                alt=""
                width="80"
                height="200"
              />
              <div className="relative my-0 -mt-28 scale-y-110 font-goboldhollow text-4xl text-gray-800 xss:text-5xl xs:text-6xl sm:text-7xl">
                Coming soon!
                <div className="absolute top-1 right-1 -z-10 w-full font-goboldRegular text-4xl text-[#fff600] xss:text-5xl xs:text-6xl sm:text-7xl">
                  <span className="">Coming soon!</span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
