import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { MintSteps } from '../../molecules';
import { Buying } from './Buying';

export const MintHeroSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="container m-auto pt-14">
        <div className="relative">
          <div className="relative z-10 mx-0 md:ml-0">
            <h3 className="mt-0 w-full text-center font-goboldRegular text-[60px] leading-[4.5rem] md:mt-12 md:text-left md:text-[80px] md:leading-[7.5rem]">
              {t('Get Your Exclusive Metaphor.')}
            </h3>
            <div className="flex flex-col items-center md:items-start">
              <div className="flex w-full max-w-lg flex-col rounded-md py-4 text-base">
                <Buying />
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 right-0 hidden h-full w-1/2 sm:block">
            <Image
              src="/assets/landing/pylon.gif"
              alt="mint image"
              layout="fill"
              objectFit="contain"
            />
          </div>
        </div>
        <MintSteps />
      </section>
    </>
  );
};
