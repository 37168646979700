import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';

import { useLoggedIn } from '@/context/useLoggedIn';
import { useMetaMask } from '@/hooks';

export const ConnectWallet: React.FC = () => {
  const { t } = useTranslation();
  const [connecting, setConnecting] = useState(false);
  const { connectMetamask } = useMetaMask();
  const { loggedIn } = useLoggedIn();

  const connectToMetamask = async () => {
    if (!loggedIn) {
      setConnecting(true);
      try {
        await connectMetamask.mutateAsync();
        window.location.reload();
      } catch {
        setConnecting(false);
      }
    } else {
      window.location.reload();
    }
  };

  return (
    <div className="my-14 flex flex-col items-center">
      <p className="mb-4">
        <img src="/assets/sit-pylon.png" alt="Connect MetaMask" />
      </p>

      <p className="text-xl font-light">
        {t('Please connect your wallet to see the page!')}
      </p>

      <div>
        {connecting ? (
          <button className="mt-8 flex max-w-[370px] items-center rounded bg-yellow-500 py-3 px-9 text-xl hover:bg-opacity-[0.7]">
            <FaSpinner className="animate-spin" />
          </button>
        ) : (
          <button
            onClick={connectToMetamask}
            className="mt-8 flex max-w-[370px] items-center rounded bg-yellow-500 py-3 px-9 text-xl hover:bg-opacity-[0.7]"
          >
            <Image
              src="/assets/MetaMask_Fox.svg"
              width="18px"
              height="18px"
              alt="metamask"
            />
            <span className="ml-3 text-base">{t('CONNECT MetaMask')}</span>
          </button>
        )}
      </div>
    </div>
  );
};
