import { useRef } from 'react';

import Play from '../../../../assets/play.svg';

export const VideoPreviewSection = () => {
  const videoRef = useRef() as React.MutableRefObject<HTMLVideoElement>;
  const buttonRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handlePlay = () => {
    videoRef.current.play();
    buttonRef.current.style.visibility = 'hidden';
  };

  const handlePause = () => {
    if (buttonRef.current.style.visibility === 'hidden') {
      videoRef.current.pause();
      buttonRef.current.style.visibility = 'visible';
    }
  };

  return (
    <section className="relative z-10 mx-[30px] mt-[84px] xsm:mt-[106px] lg:mx-[162px] lg:mt-[50px] lg:h-[414px]">
      <div className="flex h-full w-full items-center justify-center">
        <video
          onEnded={handlePause}
          onClick={handlePause}
          ref={videoRef}
          className="h-full "
          playsInline={true}
          src="/assets/video/long20sec.mp4"
          controls={false}
          width={'100%'}
        />
        <div ref={buttonRef} className="absolute h-[70px] w-[60px]">
          <Play className="cursor-pointer" onClick={handlePlay} />
        </div>
      </div>
    </section>
  );
};
