import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

interface MintTimerSectionProps {
  showCaseDay: string;
}

export const MintTimerSection: React.FC<MintTimerSectionProps> = ({
  showCaseDay,
}) => {
  const { t } = useTranslation();

  const [timeLeft, setTimeLeft] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
  });

  const calculateTimeLeft = () => {
    const utcDate = new Date(new Date().toUTCString().slice(0, -4));
    const difference = Math.max(
      0,
      +new Date(showCaseDay.replace(/-/g, '/')) - +utcDate
    );
    setTimeLeft({
      days: Math.floor(difference / (1000 * 60 * 60 * 24))
        .toString()
        .padStart(2, '0'),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24)
        .toString()
        .padStart(2, '0'),
      minutes: Math.floor((difference / 1000 / 60) % 60)
        .toString()
        .padStart(2, '0'),
    });
  };

  useEffect(() => {
    calculateTimeLeft();

    setTimeout(() => {
      calculateTimeLeft();
    }, 10000);
  }, []);

  return (
    <>
      <section className="flex h-[649px] w-full flex-row items-center justify-center bg-[url('/assets/landing/timer-background.png')] bg-cover bg-fixed md:px-64">
        <div className="container flex w-full flex-col items-center justify-center gap-10 p-4 align-middle text-yellow-500 md:flex-row md:pt-0">
          <div className="relative mr-5 lg:max-w-sm 2xl:max-w-7xl">
            <p className="z-10 max-w-lg justify-start text-left font-goboldhollow  text-[45px] font-light leading-[4rem] md:justify-start md:text-[107px] md:leading-[8rem]">
              {t('SHOW CASE YOUR NFT')}
            </p>
            <p className="absolute left-0 top-0 max-w-lg justify-start text-left font-goboldRegular text-[45px] font-light leading-[4rem] md:-left-1 md:-top-1 md:justify-start md:text-[107px] md:leading-[8rem]">
              {t('SHOW CASE YOUR NFT')}
            </p>
          </div>

          <div className="ml-0 flex flex-1 flex-col">
            <span className="text-center font-goboldhollow text-4xl font-semibold uppercase">
              {t('Remaining time')}
            </span>
            <div className="flex justify-center">
              <div className="flex flex-col">
                <span className="font-circo text-6xl font-bold">{`${timeLeft?.days}:`}</span>
                <span className="mr-[17px] pt-2 text-center font-goboldRegular text-2xl uppercase">
                  {t('Day')}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="font-circo text-6xl font-bold">{`${timeLeft?.hours}:`}</span>
                <span className="mr-[17px] pt-2 text-center font-goboldRegular text-2xl uppercase">
                  {t('Hour')}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="font-circo text-6xl font-bold">{`${timeLeft.minutes}`}</span>
                <span className="mr-[17px] pt-2 text-center font-goboldRegular text-2xl uppercase">
                  {t('Min')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
