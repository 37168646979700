import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

interface StudioTabBarProps {
  currentTab: string;
}

export const StickersTabBar: React.FC<StudioTabBarProps> = ({ currentTab }) => {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <>
      <section className="flex-col px-5 sm:block md:px-0 lg:visible">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-8">
            <div
              className={`${
                currentTab === 'packs' ? 'text-black-500' : 'text-[#cfcfcf]'
              } flex flex-row gap-8 py-2 font-semibold uppercase`}
            >
              <div className="flex flex-col justify-center font-goboldRegular text-3xl md:text-2xl">
                <Link href="/studio/stickerpacks">{t('packs')}</Link>
                {currentTab === 'packs' ? (
                  <div className="m-auto mt-2 h-[5px] w-[80%] bg-black-500" />
                ) : (
                  <div className="m-auto mt-2 h-[5px] w-[80%] bg-transparent" />
                )}
              </div>
            </div>
            <div
              className={`${
                currentTab === 'mycollection'
                  ? 'text-black-500'
                  : 'text-[#cfcfcf]'
              } flex flex-row gap-8 py-2 font-semibold uppercase`}
            >
              <div className="font-goboldRegular text-3xl md:text-2xl">
                <Link href="/studio/stickerpacks/mycollection">
                  {t('my collection')}
                </Link>
                {currentTab === 'mycollection' ? (
                  <div className="m-auto mt-2 h-[5px] w-[80%] bg-black-500" />
                ) : (
                  <div className="m-auto mt-2 h-[5px] w-[80%] bg-transparent" />
                )}
              </div>
            </div>
            {router?.pathname.includes('openpack') && (
              <div
                className={`${
                  currentTab === 'openpack'
                    ? 'active text-black-500'
                    : 'text-gray-500'
                } flex flex-row gap-8 py-2 font-semibold uppercase`}
              >
                <div className="font-goboldRegular text-sm md:text-2xl">
                  <Link href="/studio/stickerpacks/openpack">
                    {t('open pack')}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
